import {Component, OnInit} from '@angular/core';

const CURRENT_VERSION = '0.7.6';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

  title = 'PermitMe';

  ngOnInit(): void {
    const storedVersion = localStorage.getItem('version');

    if(storedVersion !== CURRENT_VERSION){
      localStorage.clear();
      localStorage.setItem('version', CURRENT_VERSION);
    }
  }
}
