export const environment = {
  production: true,
  firebase: {
    projectId: 'todd-hoops',
    appId: '1:420850411312:web:449c6cd3a7c0f0beb64af5',
    storageBucket: 'todd-hoops.appspot.com',
    apiKey: 'AIzaSyCVjdCZd8-8hME3AWqJQWy_cg6j0ECnVv8',
    authDomain: 'todd-hoops.firebaseapp.com',
    messagingSenderId: '420850411312',
    measurementId: 'G-XQ1BX01LGP',
  },
};

